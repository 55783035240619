.experience {
  display: block;
  align-items: center;
  margin-bottom: 20px;
  /* Adjust spacing between skills */

}

.experience-company {
  font-weight: 700;
  cursor: pointer;
  /* Change cursor to pointer */
}

.experience-date {
  position: relative;
  background-color: #BAA2A4;
  font-weight: 500;
  color: rgb(71, 99, 123);
  transition: background-color 0.3s ease;
  transition: color 0.3s ease;
}

.experience:hover .experience-date {
  background-color: rgb(246, 244, 235);
  color: inherit;
}

.experience-description {
  width: 100%;
  position: relative;
}

/* Experience.css */
.experience-description ul,
.experience-accomplishments ul {
  margin-top: 10px;
  padding-left: 20px;
  /* Indent bullets */
}

.experience-description li,
.experience-accomplishments li {
  margin-bottom: 5px;
  /* Add spacing between bullet points */
}


.experience-accomplishments {
  width: 100%;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.experience-skills {
  width: 100%;
  position: relative;

}